import React from 'react';
import { motion } from 'framer-motion';
import Particles from 'react-tsparticles';
import { loadFull } from 'tsparticles';

function About() {
  const particlesInit = async (engine) => {
    await loadFull(engine);
  };

  return (
    <div
      style={{
        position: 'relative',
        fontFamily: "'Arial', sans-serif",
        color: '#6B5755',
        backgroundColor: '#ffffff',
        overflow: 'visible',
      }}
    >
      {/* Navbar */}
      <nav
        style={{
          position: 'fixed',
          top: 0,
          width: '100%',
          backgroundColor: '#ffffff',
          borderBottom: '1px solid #f0f0f0',
          zIndex: 100,
          display: 'flex',
          justifyContent: 'center',
          padding: '10px 0',
        }}
      >
        <div style={{ display: 'flex', gap: '30px' }}>
          <a href="/" style={{ textDecoration: 'none', color: '#6B5755' }}>
            Home
          </a>
          <a
            href="/about"
            style={{ textDecoration: 'none', color: '#9C3336', fontWeight: 'bold' }}
          >
            About
          </a>
          <a href="/contact" style={{ textDecoration: 'none', color: '#6B5755' }}>
            Contact
          </a>
        </div>
      </nav>

      {/* Particles Background */}
      <Particles
        id="tsparticles"
        init={particlesInit}
        options={{
          background: {
            color: { value: '#ffffff' },
          },
          fpsLimit: 60,
          interactivity: {
            events: {
              onHover: { enable: true, mode: 'repulse' },
              resize: true,
            },
            modes: {
              repulse: {
                distance: 100,
                duration: 0.4,
              },
            },
          },
          particles: {
            color: { value: '#6B5755' },
            links: {
              color: '#9C3336',
              distance: 200,
              enable: true,
              opacity: 0.5,
              width: 2,
            },
            move: {
              enable: true,
              speed: 0.5,
              outModes: { default: 'out' },
            },
            number: {
              density: { enable: true, area: 800 },
              value: 50,
            },
            opacity: { value: 0.7 },
            shape: {
              type: 'circle',
            },
            size: {
              value: { min: 3, max: 7 },
            },
          },
          detectRetina: true,
        }}
        style={{
          position: 'absolute',
          zIndex: -1,
          width: '100%',
          height: '100%',
        }}
      />

      {/* Main Content */}
      <div
        style={{
          position: 'relative',
          minHeight: '100vh',
          padding: '0 20px',
          marginTop: '60px',
        }}
      >
        {/* About Us Heading */}
        <section
          style={{
            minHeight: '100vh',
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            textAlign: 'center',
            justifyContent: 'center',
            position: 'relative',
          }}
        >
          <motion.h1
            style={{ fontSize: '3rem', color: '#9C3336', marginBottom: '20px' }}
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            transition={{ duration: 1 }}
          >
            About Us
          </motion.h1>
        </section>

        {/* Founder Section */}
        <section
          style={{
            padding: '50px 20px',
            textAlign: 'center',
            maxWidth: '800px',
            margin: '0 auto',
          }}
        >
          <motion.h2
  style={{ fontSize: '2rem', color: '#9C3336', marginBottom: '20px' }}
  initial={{ opacity: 0 }}
  animate={{ opacity: 1 }}
  transition={{ delay: 0.2, duration: 1 }}
>
  Meet Our Founder
  </motion.h2>
  <motion.p
    style={{
      fontSize: '1.2rem',
      lineHeight: '1.8',
    }}
    initial={{ opacity: 0 }}
    animate={{ opacity: 1 }}
    transition={{ delay: 0.4, duration: 1 }}
  >
    Lokos AI is led by Diego Casabuena, an entrepreneur, martial artist, and private pilot, with a rich and diverse international background.
    Having spent a decade in the United States, he has also lived in Venezuela, Spain, the UK, Israel, and China.
    He holds a double major in{' '}
    <a
      href="https://cs.nyu.edu/dynamic/achievements/undergraduate/#"
      target="_blank"
      rel="noopener noreferrer"
      style={{ color: 'rgb(156, 51, 54)', fontWeight: 'bold', textDecoration: 'underline' }}
    >
      Computer Science
    </a>
    {' '}and Economics from NYU, where he focused on machine learning and decision theory, complementing his studies with ad-hoc PhD-level coursework in both fields.
    As a student researcher at the NYU Center for Data Science, he was involved in early advancements in language model development.
    <br />
    <br />
    Diego's professional experience spans a wide range of industries, including fintech, social media, dating, and streaming applications.
    At Podz, he spearheaded the development of fine-tuned language models based on podcast transcription, creating the world's first audio feed.
    This groundbreaking innovation led to{' '}
    <a
      href="https://techcrunch.com/2021/06/17/spotify-acquires-podz-a-podcast-discovery-app/"
      target="_blank"
      rel="noopener noreferrer"
      style={{ color: 'rgb(156, 51, 54)', fontWeight: 'bold', textDecoration: 'underline' }}
    >
      Podz's acquisition by Spotify,
    </a>
    {' '}where Diego served as a Senior Machine Learning Engineer.
    <br />
    <br />
    At Spotify, Diego advanced the Podz technology to introduce podcast preview features,{' '}
    <a
      href="https://www.forbes.com/sites/hughmcintyre/2023/03/08/spotifys-stream-on-updates-event-heres-whats-coming-to-the-streaming-music-platform-in-2023/"
      target="_blank"
      rel="noopener noreferrer"
      style={{ color: 'rgb(156, 51, 54)', fontWeight: 'bold', textDecoration: 'underline' }}
    >
      significantly influencing the platform's evolution.
    </a>
    {' '}He also led the development of one of the first commercially implemented{' '}
    <a
      href="https://newsroom.spotify.com/2023-09-25/ai-voice-translation-pilot-lex-fridman-dax-shepard-steven-bartlett/"
      target="_blank"
      rel="noopener noreferrer"
      style={{ color: 'rgb(156, 51, 54)', fontWeight: 'bold', textDecoration: 'underline' }}
    >
      audio-visual translation systems,
    </a>
    {' '}enabling translations for prominent podcasters like Lex Fridman.
    In addition, he contributed to model development in transformative projects such as{' '}
    <a
      href="https://newsroom.spotify.com/2023-02-22/spotify-debuts-a-new-ai-dj-right-in-your-pocket/"
      target="_blank"
      rel="noopener noreferrer"
      style={{ color: 'rgb(156, 51, 54)', fontWeight: 'bold', textDecoration: 'underline' }}
    >
      Spotify DJ
    </a>
    {' '}and{' '}
    <a
      href="https://community.spotify.com/t5/FAQs/Automix-Overview/ta-p/5257278"
      target="_blank"
      rel="noopener noreferrer"
      style={{ color: 'rgb(156, 51, 54)', fontWeight: 'bold', textDecoration: 'underline' }}
    >
      Auto-Mix.
    </a>
    {' '}Diego's publication record ranges from{' '}
    <a
      href="https://arxiv.org/abs/2207.12504"
      target="_blank"
      rel="noopener noreferrer"
      style={{ color: 'rgb(156, 51, 54)', fontWeight: 'bold', textDecoration: 'underline' }}
    >
      advanced diarization model research
    </a>
    {' '}to{' '}
    <a
      href="https://engineering.atspotify.com/2023/04/large-scale-generation-of-ml-podcast-previews-at-spotify-with-google-dataflow/"
      target="_blank"
      rel="noopener noreferrer"
      style={{ color: 'rgb(156, 51, 54)', fontWeight: 'bold', textDecoration: 'underline' }}
    >
      large-scale engineering
    </a>
    {' '}and{' '}
    <a
      href="https://theeconreview.com/2017/10/19/the-insanity-of-catalonian-secession/"
      target="_blank"
      rel="noopener noreferrer"
      style={{ color: 'rgb(156, 51, 54)', fontWeight: 'bold', textDecoration: 'underline' }}
    >
      economic commentary.
    </a>
    <br />
    <br />
    His expertise encompasses traditional machine learning, natural language processing (NLP), and audio, image, and video ML technologies.
    Beyond the realm of AI, Lokos AI brings valuable experience in robotics applications and financial modeling.
  </motion.p>
        </section>

        {/* Quotes Section */}
        <section style={{ padding: '50px 20px', textAlign: 'center' }}>
          <motion.blockquote
            style={{
              fontSize: '1.5rem',
              fontStyle: 'italic',
              margin: '40px auto',
              maxWidth: '800px',
              color: '#6B5755',
            }}
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            transition={{ delay: 0.8, duration: 1 }}
          >
            "Why use humans to train ML when you can use ML to train humans."
          </motion.blockquote>
          <motion.blockquote
            style={{
              fontSize: '1.5rem',
              fontStyle: 'italic',
              margin: '40px auto',
              maxWidth: '800px',
              color: '#6B5755',
            }}
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            transition={{ delay: 0.6, duration: 1 }}
          >
            "You can't replace jobs that never existed in the first place."
          </motion.blockquote>
        </section>

        {/* Transparent Video Section */}
        <div style={{ padding: '20px 0', textAlign: 'center' }}>
          <video
            src="/office.mp4"
            autoPlay
            loop
            muted
            style={{
              width: '50%',
              opacity: 0.8,
              display: 'block',
              margin: '20px auto',
            }}
          />
        </div>
      </div>
    </div>
  );
}

export default About;
