import React, { useState, useEffect, useRef } from 'react';
import { useParams } from 'react-router-dom';
import { jwtDecode } from 'jwt-decode';
import {
  CircularProgress,
  Typography,
  Button,
  Avatar,
  Chip,
  Box,
} from '@mui/material';
import { styled } from '@mui/system';
import { Device } from 'twilio-client';

import { DOMAIN_NAME, API_BASE_URL, GOOGLE_CLIENT_ID } from '../constants';

function Waveform({ audioDataRef }) {
  const canvasRef = useRef(null);

  useEffect(() => {
    const canvas = canvasRef.current;
    const canvasCtx = canvas.getContext('2d');

    let animationId;

    const draw = () => {
      if (!canvas) return;

      canvas.width = canvas.clientWidth;
      canvas.height = 100; // Fixed height for consistent visualization

      if (!audioDataRef.current) {
        canvasCtx.clearRect(0, 0, canvas.width, canvas.height);
        animationId = requestAnimationFrame(draw);
        return;
      }

      const bufferLength = audioDataRef.current.length;
      const dataArray = audioDataRef.current;

      canvasCtx.clearRect(0, 0, canvas.width, canvas.height);

      canvasCtx.fillStyle = 'black';
      canvasCtx.fillRect(0, 0, canvas.width, canvas.height);

      canvasCtx.lineWidth = 2;
      canvasCtx.strokeStyle = 'white';

      canvasCtx.beginPath();

      const sliceWidth = canvas.width / bufferLength;
      let x = 0;

      for (let i = 0; i < bufferLength; i++) {
        // dataArray values are between -1.0 and 1.0.
        const v = dataArray[i] * 0.5 + 0.5;
        const y = v * canvas.height;

        if (i === 0) {
          canvasCtx.moveTo(x, y);
        } else {
          canvasCtx.lineTo(x, y);
        }

        x += sliceWidth;
      }

      canvasCtx.stroke();

      animationId = requestAnimationFrame(draw);
    };

    draw();

    return () => {
      cancelAnimationFrame(animationId);
    };
  }, [audioDataRef]);

  return (
    <canvas
      ref={canvasRef}
      style={{
        width: '100%',
        height: '100px',
        marginTop: '20px',
      }}
    />
  );
}

function WebAudioDemo() {
  const { username } = useParams();

  const [user, setUser] = useState(null);
  const [userData, setUserData] = useState(null);
  const [userImage, setUserImage] = useState(null);
  const [authorized, setAuthorized] = useState(false);
  const [loading, setLoading] = useState(true);
  const [imageLoading, setImageLoading] = useState(true);
  const [callInProgress, setCallInProgress] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');
  const [transcript, setTranscript] = useState([]);
  const [bullets, setBullets] = useState('');

  const [callSid, setCallSid] = useState(null); // For future use
  const summaryInterval = 5000; // Interval for fetching bullet points

  const twilioDeviceRef = useRef(null);
  const transcriptRef = useRef([]);
  const intervalIdRef = useRef(null);

  const audioContextRef = useRef(null);
  const analyserRef = useRef(null);
  const audioDataRef = useRef(null);

  useEffect(() => {
    transcriptRef.current = transcript;
  }, [transcript]);

  const handleCredentialResponse = (response) => {
    const decoded = jwtDecode(response.credential);
    if (decoded.hd === DOMAIN_NAME) {
      setUser(decoded);
      setAuthorized(true);

      localStorage.setItem('user', JSON.stringify(decoded));
      localStorage.setItem('expiry', Date.now() + 3600000); // 1 hour
    } else {
      alert(`Access restricted to ${DOMAIN_NAME} email addresses.`);
    }
  };

  useEffect(() => {
    const loadGoogleSignIn = () => {
      if (
        typeof window.google !== 'undefined' &&
        window.google.accounts &&
        window.google.accounts.id
      ) {
        window.google.accounts.id.initialize({
          client_id: GOOGLE_CLIENT_ID,
          callback: handleCredentialResponse,
        });
        window.google.accounts.id.renderButton(
          document.getElementById('googleSignInDiv'),
          {
            theme: 'outline',
            size: 'large',
            hosted_domain: DOMAIN_NAME,
          }
        );
      } else {
        setTimeout(loadGoogleSignIn, 100);
      }
    };

    const storedUser = localStorage.getItem('user');
    const expiry = localStorage.getItem('expiry');
    if (storedUser && expiry && Date.now() < expiry) {
      const decodedUser = JSON.parse(storedUser);
      setUser(decodedUser);
      setAuthorized(true);
    } else {
      localStorage.removeItem('user');
      localStorage.removeItem('expiry');
      loadGoogleSignIn();
    }
  }, []);

  useEffect(() => {
    if (authorized) {
      fetch(`${API_BASE_URL}/sget/users?username=${username}`)
        .then((res) => res.json())
        .then((data) => {
          if (data.success) {
            setUserData(data.users[0]);
          } else {
            setErrorMessage(data.error);
          }
          setLoading(false);
        })
        .catch(() => {
          setErrorMessage('Error fetching user data.');
          setLoading(false);
        });
    }
  }, [authorized, username]);

  useEffect(() => {
    if (userData) {
      fetch(`${API_BASE_URL}/sget_user_mugshot/${userData.id}`)
        .then((res) => res.json())
        .then((data) => {
          if (data.success) {
            setUserImage(data.url);
          } else {
            setErrorMessage(data.error);
          }
          setImageLoading(false);
        })
        .catch(() => {
          setErrorMessage('Error fetching user image.');
          setImageLoading(false);
        });
    }
  }, [userData]);

  // Initialize AudioContext and Analyser once
  useEffect(() => {
    if (!audioContextRef.current) {
      audioContextRef.current = new (window.AudioContext || window.webkitAudioContext)();
    }
    if (!analyserRef.current && audioContextRef.current) {
      analyserRef.current = audioContextRef.current.createAnalyser();
      analyserRef.current.fftSize = 2048;
      audioDataRef.current = new Float32Array(analyserRef.current.fftSize);

      // Continuously update the audio data
      const updateAudioData = () => {
        if (analyserRef.current && audioDataRef.current) {
          analyserRef.current.getFloatTimeDomainData(audioDataRef.current);
        }
        requestAnimationFrame(updateAudioData);
      };
      updateAudioData();
    }
  }, []);

  // Effect to handle bullet summary updates
  useEffect(() => {
    if (callInProgress) {
      intervalIdRef.current = setInterval(async () => {
        if (transcriptRef.current.length > 0) {
          try {
            const response = await fetch('/api/segment_summary', {
              method: 'POST',
              headers: {
                'Content-Type': 'application/json',
              },
              body: JSON.stringify({
                transcript: transcriptRef.current
                  .map((item) =>
                    `${item.role === 'agent' ? username : 'You'}: ${item.content}`
                  )
                  .join('\n'),
              }),
            });

            const data = await response.json();

            if (data.success) {
              setBullets(data.bullets);
            } else {
              setErrorMessage(data.error || 'Error fetching bullets.');
            }
          } catch (error) {
            console.error('Error fetching bullets:', error);
            setErrorMessage('Error fetching bullets.');
          }
        }
      }, summaryInterval);
    } else {
      if (intervalIdRef.current) {
        clearInterval(intervalIdRef.current);
        intervalIdRef.current = null;
      }
    }
  }, [callInProgress, username]);

  const handleStartCall = async () => {
    try {
      const response = await fetch(
        `${API_BASE_URL}/screate_web_call/${userData.id}`,
        {
          method: 'POST',
          headers: { 'Content-Type': 'application/json' },
          body: JSON.stringify({ access_type: 'twilio' }),
        }
      );

      const data = await response.json();

      if (data.success) {
        const accessToken = data.access_token;
        const newDevice = new Device(accessToken);

        newDevice.on('ready', () => console.log('Twilio device ready.'));
        newDevice.on('disconnect', () => setCallInProgress(false));
        newDevice.on('error', (error) => {
          console.error('Twilio error:', error);
          setErrorMessage('Error with Twilio call.');
        });

        // On connect, hook the audio into the analyser
        newDevice.on('connect', (connection) => {
          const remoteStream = connection.getRemoteStream();
          if (remoteStream && audioContextRef.current && analyserRef.current) {
            const source = audioContextRef.current.createMediaStreamSource(remoteStream);
            source.connect(analyserRef.current);
          }

          // Placeholder for future transcript streaming logic:
          // We have the callSid from data.call_sid
          setCallSid(data.call_sid);
          // In the future, we can open a SSE/WebSocket connection here to receive transcripts.
        });

        newDevice.connect({ username });
        twilioDeviceRef.current = newDevice;
        setCallInProgress(true);
      } else {
        setErrorMessage(data.error || 'Error starting call.');
      }
    } catch (error) {
      setErrorMessage('Error starting call.');
      console.error(error);
    }
  };

  const handleStopCall = () => {
    try {
      if (twilioDeviceRef.current) {
        twilioDeviceRef.current.disconnectAll();
      }
      setCallInProgress(false);
    } catch (error) {
      setErrorMessage('Error ending call.');
      console.error(error);
    }
  };

  // Placeholder effect for future transcript streaming using callSid.
  useEffect(() => {
    if (callSid) {
      // In the future:
      // Open a connection to your server that streams transcripts as they are generated.
      // Update the transcript state with incoming data.
      // setTranscript([...transcript, newLine]);
    }
  }, [callSid]);

  if (!authorized) {
    return (
      <Box
        sx={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
          height: '100vh',
          backgroundColor: 'black',
        }}
      >
        <div id="googleSignInDiv"></div>
      </Box>
    );
  }

  if (loading || !userData) {
    return (
      <Box
        sx={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
          height: '100vh',
          backgroundColor: 'black',
        }}
      >
        <CircularProgress color="inherit" />
      </Box>
    );
  }

  if (errorMessage) {
    return (
      <Box
        sx={{
          color: 'white',
          backgroundColor: 'black',
          height: '100vh',
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
        }}
      >
        <Typography>{errorMessage}</Typography>
      </Box>
    );
  }

  const StyledButton = styled(Button)({
    borderColor: 'white',
    color: 'white',
    width: '150px',
    height: '50px',
    margin: '20px auto',
  });

  const StyledAvatar = styled(Avatar)({
    width: '100px',
    height: '100px',
    margin: '0 auto',
  });

  const WaveformContainer = styled('div')({
    width: '100%',
    height: '100px',
    marginTop: '20px',
  });

  const ContentContainer = styled('div')({
    color: 'white',
    textAlign: 'center',
    backgroundColor: 'black',
    height: '100vh',
    display: 'flex',
    flexDirection: 'column',
  });

  const UpperSection = styled('div')({
    flex: 5,
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
  });

  const MiddleSection = styled('div')({
    flex: 3,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  });

  const LowerSection = styled('div')({
    flex: 2,
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
  });

  return (
    <ContentContainer>
      <UpperSection>
        {imageLoading ? (
          <CircularProgress color="inherit" />
        ) : (
          <StyledAvatar src={userImage} />
        )}
        <WaveformContainer>
          <Waveform audioDataRef={audioDataRef} />
        </WaveformContainer>
        <Typography variant="body1" sx={{ marginTop: 2 }}>
          {transcript.map((item, index) => (
            <div key={index}>
              {item.role === 'agent' ? username : 'You'}: {item.content}
            </div>
          ))}
        </Typography>
        {callInProgress ? (
          <StyledButton variant="outlined" onClick={handleStopCall}>
            Stop
          </StyledButton>
        ) : (
          <StyledButton
            variant="outlined"
            onClick={handleStartCall}
            disabled={!userData}
          >
            Start
          </StyledButton>
        )}
      </UpperSection>
      <MiddleSection>
        <Typography variant="h4" align="center">
          {bullets
            ? bullets.split('\n').map((bullet, index) => (
                <React.Fragment key={index}>
                  {bullet}
                  <br />
                </React.Fragment>
              ))
            : ''}
        </Typography>
      </MiddleSection>
      <LowerSection>
        <Typography variant="h5">
          {userData.first_name} {userData.last_name}, {userData.age}
        </Typography>
        <Box sx={{ display: 'flex', gap: 1, marginTop: 1 }}>
          {userData.tags &&
            userData.tags.split(',').map((tag, index) => (
              <Chip
                key={index}
                label={tag.trim()}
                variant="outlined"
                sx={{ color: 'white', borderColor: 'white' }}
              />
            ))}
        </Box>
      </LowerSection>
    </ContentContainer>
  );
}

export default WebAudioDemo;
