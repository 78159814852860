import React, { useState, useEffect, useRef } from 'react';
import { createClient } from '@anam-ai/js-sdk';
import { AnamEvent } from '@anam-ai/js-sdk/dist/module/types';
import { jwtDecode } from 'jwt-decode';
import { API_BASE_URL, GOOGLE_CLIENT_ID, DOMAIN_NAME } from '../constants';

export default function SingleFileComponent() {
  const [authorized, setAuthorized] = useState(false);
  const [user, setUser] = useState(null);
  const [sessionToken, setSessionToken] = useState(null);
  const [error, setError] = useState('');
  const [loading, setLoading] = useState(true);
  const [secondsElapsed, setSecondsElapsed] = useState(0);
  const [messageHistory, setMessageHistory] = useState([]);
  const [talkMessageStream, setTalkMessageStream] = useState(null);

  const remoteVideoRef = useRef(null);
  const remoteAudioRef = useRef(null);
  const anamClientRef = useRef(null);

  const handleCredentialResponse = (response) => {
    const decoded = jwtDecode(response.credential);
    if (decoded.hd === DOMAIN_NAME) {
      setUser(decoded);
      setAuthorized(true);
      localStorage.setItem('user', JSON.stringify(decoded));
      localStorage.setItem('expiry', Date.now() + 3600000); // 1 hour
    } else {
      alert(`Access restricted to ${DOMAIN_NAME} email addresses.`);
    }
  };

  useEffect(() => {
    const loadGoogleSignIn = () => {
      if (window.google?.accounts?.id) {
        window.google.accounts.id.initialize({
          client_id: GOOGLE_CLIENT_ID,
          callback: handleCredentialResponse,
        });
        window.google.accounts.id.renderButton(
          document.getElementById('googleSignInDiv'),
          { theme: 'outline', size: 'large', hosted_domain: DOMAIN_NAME }
        );
      } else {
        setTimeout(loadGoogleSignIn, 100);
      }
    };

    const storedUser = localStorage.getItem('user');
    const expiry = localStorage.getItem('expiry');
    if (storedUser && expiry && Date.now() < expiry) {
      setUser(JSON.parse(storedUser));
      setAuthorized(true);
    } else {
      localStorage.removeItem('user');
      localStorage.removeItem('expiry');
      loadGoogleSignIn();
    }
  }, []);

  useEffect(() => {
    if (!authorized) return;

    const fetchSessionToken = async () => {
      try {
        const response = await fetch(`${API_BASE_URL}/get-anam-session-token`, {
          method: 'GET',
          headers: { 'Content-Type': 'application/json' },
        });
        const data = await response.json();
        if (data.sessionToken) {
          setSessionToken(data.sessionToken);
        } else {
          setError('Session token not received');
        }
      } catch (err) {
        console.error('An error occurred:', err);
        setError(err.message);
      }
    };

    fetchSessionToken();
  }, [authorized]);

  const fetchNextResponse = async (messages) => {
    const response = await fetch(`${API_BASE_URL}/sget_course_agent_response`, {
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify({ transcript: messages }),
    });
    const data = await response.json();
    return data.next_response;
  };

  const handleNewMessage = async (messages) => {
    const nextResponse = await fetchNextResponse(messages);

    if (nextResponse) {
      // Extract the content strings from the response objects
      const contentChunks = nextResponse.map(response => response.content);

      // Close the existing stream if active
      if (talkMessageStream && talkMessageStream.isActive()) {
        talkMessageStream.endMessage();
      }

      // Create a new talk message stream
      const newTalkStream = anamClientRef.current.createTalkMessageStream();
      setTalkMessageStream(newTalkStream);

      // Stream each chunk of content
      for (const chunk of contentChunks) {
        if (newTalkStream.isActive()) {
          newTalkStream.streamMessageChunk(
            chunk,
            chunk === contentChunks[contentChunks.length - 1] // Mark last chunk as complete
          );
        }
      }
    }
  };

  useEffect(() => {
    if (!sessionToken || !authorized) return;

    const initializeAnamClient = async () => {
      try {
        const anamClient = createClient(sessionToken, {
          personaId: 'ebc07e30-64a2-40e3-9584-28f44d62ee0c',
          disableBrains: true,
        });
        anamClientRef.current = anamClient;

        anamClient.addListener(AnamEvent.CONNECTION_ESTABLISHED, () => {
          console.log('Connection established');
        });

        anamClient.addListener(AnamEvent.VIDEO_PLAY_STARTED, () => {
          setLoading(false);
        });

        anamClient.addListener(AnamEvent.MESSAGE_HISTORY_UPDATED, (messages) => {
          setMessageHistory(messages);
          if (messages.length > 0 && messages[messages.length - 1].role === 'user') {
            handleNewMessage(messages);
          }
        });

        await navigator.mediaDevices.getUserMedia({ audio: true });
        await anamClient.streamToVideoAndAudioElements(remoteVideoRef.current.id, remoteAudioRef.current.id);
      } catch (err) {
        console.error('Error initializing Anam client:', err);
        setError(err.message);
      }
    };

    if (anamClientRef.current) {
      anamClientRef.current.stopStreaming();
      anamClientRef.current.removeAllListeners();
    }

    initializeAnamClient();
  }, [sessionToken, authorized]);

  useEffect(() => {
    if (!authorized) return;
    const interval = setInterval(() => setSecondsElapsed((prev) => prev + 1), 1000);
    return () => clearInterval(interval);
  }, [authorized]);

  const formatTime = (seconds) => {
    const minutes = Math.floor(seconds / 60);
    const remainingSeconds = seconds % 60;
    return `${String(minutes).padStart(2, "0")}:${String(remainingSeconds).padStart(2, "0")}`;
  };

  if (!authorized) {
    return <div id="googleSignInDiv" style={{ display: 'flex', justifyContent: 'center', marginTop: '20%' }}></div>;
  }

  return (
    <div style={{ display: 'flex', flexDirection: 'column', minHeight: '100vh', background: '#fafafa' }}>
      <header style={{ padding: '16px', borderBottom: '1px solid #ccc', background: '#fff', display: 'flex', justifyContent: 'space-between' }}>
        <h1>Lokos AI Agent</h1>
      </header>
      {error && <div style={{ color: 'red', textAlign: 'center' }}>{error}</div>}
      <div style={{ flex: 1, position: 'relative', background: '#f0f0f0', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
        {loading && <div style={{ position: 'absolute', zIndex: 10, color: '#fff', background: 'rgba(0,0,0,0.7)' }}>Connecting...</div>}
        <video ref={remoteVideoRef} id="avatar-video" autoPlay playsInline style={{ maxWidth: '100%', background: '#333' }}></video>
        <audio ref={remoteAudioRef} id="avatar-audio" autoPlay></audio>
        <div style={{ position: 'absolute', top: '8px', color: '#333', fontWeight: 'bold' }}>{formatTime(secondsElapsed)}</div>
      </div>
    </div>
  );
}
