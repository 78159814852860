import React, { useState } from 'react';
import {
  Container,
  TextField,
  Button,
  Typography,
  Grid,
  MenuItem,
  Box,
  Alert,
  Autocomplete,
} from '@mui/material';
import { API_BASE_URL } from '../constants';

function AdminAddUser() {
  const [formData, setFormData] = useState({
    first_name: '',
    last_name: '',
    age: '',
    gender: '',
    email: '',
    phone_number: '',
    referral: '',
    goal: '',
    timezone: 'America/New_York',
    alias: '',
    username: '',
    tags: [],
    document_refs: [],
  });

  const [successMessage, setSuccessMessage] = useState('');
  const [errorMessage, setErrorMessage] = useState('');

  const genders = [
    { value: 'Male', label: 'Male' },
    { value: 'Female', label: 'Female' },
    { value: 'Non-binary', label: 'Non-binary' },
    { value: 'Other', label: 'Other' },
  ];

  const timezones = [
    { value: 'America/New_York', label: 'Eastern Time (US & Canada)' },
    { value: 'America/Chicago', label: 'Central Time (US & Canada)' },
    { value: 'America/Denver', label: 'Mountain Time (US & Canada)' },
    { value: 'America/Los_Angeles', label: 'Pacific Time (US & Canada)' },
    { value: 'Europe/London', label: 'London' },
    { value: 'Europe/Paris', label: 'Paris' },
    { value: 'Asia/Tokyo', label: 'Tokyo' },
    { value: 'Asia/Singapore', label: 'Singapore' },
    { value: 'Australia/Sydney', label: 'Sydney' },
    { value: 'UTC', label: 'UTC' },
  ];

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };

  const validatePhoneNumber = (phoneNumber) => {
    // Regex to validate international phone numbers, e.g., +1234567890, +44 1234567890
    const regex = /^\+\d{1,3}\s?\d+$/;
    return regex.test(phoneNumber);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    // Validate required fields
    if (!formData.first_name || !formData.last_name) {
      setErrorMessage('First name and last name are required.');
      return;
    }

    // Validate phone number format
    if (formData.phone_number && !validatePhoneNumber(formData.phone_number)) {
      setErrorMessage('Phone number must not contain spaces and have a prefix');
      return;
    }

    // Clear previous messages
    setSuccessMessage('');
    setErrorMessage('');

    const dataToSend = {
      ...formData,
    };

    try {
      const response = await fetch(`${API_BASE_URL}/sadd_user/`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(dataToSend),
      });

      const result = await response.json();

      if (response.ok && result.success) {
        setSuccessMessage('User added successfully!');
        setFormData({
          first_name: '',
          last_name: '',
          age: '',
          gender: '',
          email: '',
          phone_number: '',
          referral: '',
          goal: '',
          timezone: 'America/New_York',
          alias: '',
          username: '',
          tags: [],
          document_refs: [],
        });
      } else {
        setErrorMessage(result.message || 'Failed to add user.');
      }
    } catch (error) {
      console.error('Error adding user:', error);
      setErrorMessage('An error occurred while adding the user.');
    }
  };

  return (
    <Container maxWidth="sm" sx={{ mt: 4 }}>
      <Typography variant="h4" align="center" gutterBottom>
        Enter User Information
      </Typography>
      {successMessage && (
        <Alert severity="success" sx={{ mb: 2 }}>
          {successMessage}
        </Alert>
      )}
      {errorMessage && (
        <Alert severity="error" sx={{ mb: 2 }}>
          {errorMessage}
        </Alert>
      )}
      <Box component="form" onSubmit={handleSubmit} noValidate>
        <Grid container spacing={2}>
          {/* First Name */}
          <Grid item xs={12} sm={6}>
            <TextField
              label="First Name"
              name="first_name"
              value={formData.first_name}
              onChange={handleChange}
              required
              fullWidth
            />
          </Grid>

          {/* Last Name */}
          <Grid item xs={12} sm={6}>
            <TextField
              label="Last Name"
              name="last_name"
              value={formData.last_name}
              onChange={handleChange}
              required
              fullWidth
            />
          </Grid>

          {/* Age */}
          <Grid item xs={12} sm={6}>
            <TextField
              label="Age"
              name="age"
              type="number"
              value={formData.age}
              onChange={handleChange}
              fullWidth
              inputProps={{ min: 0 }}
            />
          </Grid>

          {/* Gender */}
          <Grid item xs={12} sm={6}>
            <TextField
              select
              label="Gender"
              name="gender"
              value={formData.gender}
              onChange={handleChange}
              fullWidth
            >
              {genders.map((option) => (
                <MenuItem key={option.value} value={option.value}>
                  {option.label}
                </MenuItem>
              ))}
            </TextField>
          </Grid>

          {/* Timezone */}
          <Grid item xs={12}>
            <TextField
              select
              label="Timezone"
              name="timezone"
              value={formData.timezone}
              onChange={handleChange}
              fullWidth
            >
              {timezones.map((option) => (
                <MenuItem key={option.value} value={option.value}>
                  {option.label}
                </MenuItem>
              ))}
            </TextField>
          </Grid>

          {/* Email */}
          <Grid item xs={12}>
            <TextField
              label="Email"
              name="email"
              type="email"
              value={formData.email}
              onChange={handleChange}
              fullWidth
            />
          </Grid>

          {/* Phone Number */}
          <Grid item xs={12}>
            <TextField
              label="Phone Number (must have intl prefix)"
              name="phone_number"
              value={formData.phone_number}
              onChange={handleChange}
              fullWidth
              placeholder="+12345678900"
            />
          </Grid>

          {/* Referral */}
          <Grid item xs={12}>
            <TextField
              label="Referral"
              name="referral"
              value={formData.referral}
              onChange={handleChange}
              fullWidth
            />
          </Grid>

          {/* Goal */}
          <Grid item xs={12}>
            <TextField
              label="What goal motivates you? (3-5 words max)"
              name="goal"
              value={formData.goal}
              onChange={handleChange}
              fullWidth
              inputProps={{ maxLength: 100 }}
            />
          </Grid>

          {/* Alias - Optional */}
          <Grid item xs={12}>
            <TextField
              label="Alias (Optional)"
              name="alias"
              value={formData.alias}
              onChange={handleChange}
              fullWidth
            />
          </Grid>

          {/* Username - Optional */}
          <Grid item xs={12}>
            <TextField
              label="Username (Optional)"
              name="username"
              value={formData.username}
              onChange={handleChange}
              fullWidth
            />
          </Grid>

          {/* Tags */}
          <Grid item xs={12}>
            <Autocomplete
              multiple
              freeSolo
              options={[]}
              value={formData.tags}
              onChange={(event, newValue) => {
                setFormData({ ...formData, tags: newValue });
              }}
              renderInput={(params) => (
                <TextField
                  {...params}
                  label="Tags"
                  placeholder="Add tags"
                  fullWidth
                />
              )}
            />
          </Grid>

          {/* Document References */}
          <Grid item xs={12}>
            <Autocomplete
              multiple
              freeSolo
              options={[]}
              value={formData.document_refs}
              onChange={(event, newValue) => {
                setFormData({ ...formData, document_refs: newValue });
              }}
              renderInput={(params) => (
                <TextField
                  {...params}
                  label="Document References"
                  placeholder="Add document references"
                  fullWidth
                />
              )}
            />
          </Grid>

          {/* Submit Button */}
          <Grid item xs={12}>
            <Button
              type="submit"
              variant="contained"
              color="primary"
              fullWidth
              size="large"
            >
              Submit
            </Button>
          </Grid>
        </Grid>
      </Box>
    </Container>
  );
}

export default AdminAddUser;
